<template>
  <DsSection full-screen>
    <div
      v-if="!isScreenSmall"
      class="ds-container"
    >
      <div class="ds-grid gallery-grid-md-lg">
        <div
          class="ds-grid__col ds-grid__col--12"
          :class="{ 'ds-margin-bottom-48': title }"
        >
          <div
            v-if="title"
            class="ds-display-flex ds-flex-direction-column"
          >
            <h2 class="ds-heading">
              {{ title }}
            </h2>
          </div>
        </div>
        <div
          v-for="(item, index) in cards"
          :key="`item-${index}`"
          class="ds-grid__col ds-grid__col--4 gallery-col-md-lg"
        >
          <DsCardBlog
            :image="item.image"
            :eyebrow="item.eyebrow"
            :title="item.title"
            :subtitle="item.subtitle"
            :link="item.link"
            show-placeholder-on-image-error
          />
        </div>
        <div class="ds-grid__col ds-grid__col--12">
          <DsLink
            v-if="link"
            :content="link.title"
            type="button"
            variant="secondary"
            :lazy-url-endpoint="link.lazyUrlEndpoint"
            :url="link.url"
            class="ds-margin-top-48 link-full-width"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <DsImageGallery
        :screen="screen"
        :items="cards.length"
        :title="title"
        :width="{ small: 272, large: 368 }"
        type="tertiary"
      >
        <template #default="{ index }">
          <DsCardBlog
            v-if="cards[index - 1]"
            :image="cards[index - 1].image"
            :eyebrow="cards[index - 1].eyebrow"
            :title="cards[index - 1].title"
            :subtitle="cards[index - 1].subtitle"
            :link="cards[index - 1].link"
          />
        </template>
        <template #image-gallery-footer>
          <DsLink
            v-if="link"
            :content="link.title"
            type="button"
            variant="secondary"
            :lazy-url-endpoint="link.lazyUrlEndpoint"
            :url="link.url"
            class="ds-margin-top-48 link-full-width"
          />
        </template>
      </DsImageGallery>
    </div>
  </DsSection>
</template>

<script setup>
import { DsSection, DsCardBlog, DsImageGallery, DsLink } from 'awds'
const { screen, isScreenSmall } = useScreen()
defineProps({
  /**
   * Section title
   */
  title: {
    type: String,
    default: ''
  },
  /**
   * Cards to be shown
   */
  cards: {
    type: Array,
    required: true,
    validator: (array) => {
      const isValidItemKeys = array.every((obj) => ['image', 'link', 'title', 'subtitle'].every((key) => key in obj))
      return isValidItemKeys && array.length <= 3
    }
  },
  link: {
    type: Object,
    default: null
  }
})
</script>
